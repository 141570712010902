import { Container } from "@/features/common/components/layout/Container";

export const PostPlaceholder = () => {
  // const router = useRouter();
  // const removeMdMinWidth = router.route === RouteName.CollectibleRoute;

  return (
    <Container data-test-id="PostPlaceholder-container">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {new Array(8).fill(0).map((_, idx) => {
          return (
            <div
              key={idx}
              className="border border-solid border-gray-200 rounded-xl"
            >
              <span className="block w-full h-48 rounded bg-gray-200 animate-pulse" />
              <div className="flex flex-col p-6">
                <span className="block w-2/5 h-4 rounded bg-gray-200 animate-pulse" />
                <span className="block w-2/4 h-4 mt-2 rounded bg-gray-200 animate-pulse" />
                <span className="block w-full h-12 mt-2 rounded bg-gray-200 animate-pulse" />
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};
