import { CameraIcon } from "@heroicons/react/solid";
import * as Avatar from "@radix-ui/react-avatar";
import React from "react";
import { twMerge } from "tailwind-merge";

interface AvatarWithFallbackProps {
  avatarUrl: string;
  displayName: string;
  accountName: string;
  sizeClassName: string;
  outlined?: boolean;
  isEditable?: boolean;
}

/**
 * @description Avatar with fallback component
 *  modified version reference from shadcn/ui, using @radix-ui/react-avatar for base component and tailwindcss for styling
 * @ref https://ui.shadcn.com/docs/components/avatar
 *
 */
export const AvatarWithFallback: React.FC<AvatarWithFallbackProps> = (
  props,
) => {
  const {
    avatarUrl,
    displayName,
    outlined,
    sizeClassName,
    accountName,
    isEditable,
  } = props;

  return (
    <Avatar.Root
      className={twMerge(
        "relative overflow-hidden flex shrink-0 rounded-full",
        outlined && "border-4 border-white",
        sizeClassName,
        isEditable && "touchable",
      )}
    >
      <Avatar.Image
        className="aspect-square h-full w-full object-cover"
        alt={`${displayName}-${accountName}`}
        src={avatarUrl}
      />
      <Avatar.Fallback
        className={twMerge(
          "flex w-full h-full items-center justify-center text-xl text-white font-bold bg-gray-300",
        )}
      >
        {!isEditable ? (
          displayName && displayName.slice(0, 1)
        ) : (
          <CameraIcon className="w-8 h-8" />
        )}
      </Avatar.Fallback>
    </Avatar.Root>
  );
};
