import Image, { ImageProps } from "next/image";
import React from "react";

import { ContentText } from "@/features/landing/components/ContentText";
import { SubTitle } from "@/features/landing/components/SubTitle";

type DearShareProProps = {
  description: { content: string; title: string };
  imagePosition: "left" | "right";
} & Pick<ImageProps, "src">;

export const DearshareProImage: React.FC<DearShareProProps> = (props) => {
  const { src, description, imagePosition } = props;
  const exClasses =
    imagePosition === "left" ? `md:flex-row` : `md:flex-row-reverse`;

  return (
    <div className={`flex md:flex-row flex-col ${exClasses}`}>
      <div className="flex-1 md:px-4">
        <Image
          layout="responsive"
          src={src}
          alt={`Photo of ${description.title}`}
          placeholder="blur"
        />
      </div>
      <div className="flex-1 flex flex-col justify-center mt-4 space-y-2">
        <SubTitle className="text-left">{description.title}</SubTitle>
        <ContentText>{description.content}</ContentText>
      </div>
    </div>
  );
};
