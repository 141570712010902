import React, { useMemo } from "react";

import { PostWriterAvatarSize } from "@/consts/avatarSizes";
import { Container } from "@/features/common/components/layout/Container";
import { UserInformation } from "@/features/user/components/writerInformation";
import { useTopWritersQuery } from "@/generated/generated-hooks";

import { JoinedWriters } from "./JoinedWriters";

export const JoinedWriterSection = () => {
  // fetching the topwriters
  const { data } = useTopWritersQuery({
    variables: {
      avatarSize: {
        maxWidth: PostWriterAvatarSize.MaxWidth,
        maxHeight: PostWriterAvatarSize.MaxHeight,
      },
    },
    ssr: false,
  });

  // map the query result into a list of userInformation
  const displayWriters: UserInformation[] = useMemo(() => {
    return data ? data.topWriters.map((item) => item.user) : [];
  }, [data]);

  return (
    <Container
      maxWidth="mw1152"
      className="px-0"
      data-test-id="JoinedWriterSection-container"
    >
      <JoinedWriters users={displayWriters} />
    </Container>
  );
};
