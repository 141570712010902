import { useTranslation } from "next-i18next";
import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import { useState } from "react";
import { Waypoint } from "react-waypoint";

import { LandingLogin } from "@/features/auth/components/LandingLogin";
import { Title } from "@/features/landing/components/Title";
import { PostPlaceholder } from "@/features/post/components/PostPlaceholder";
import { getPublicConfig } from "@/helpers/getPublicConfig";

import { DearshareProSection } from "./DearsharePro/DearshareProSection";
import { DearshareReasonSection } from "./DearshareReason/DearshareReasonSection";
import { DearshareWhoSuitableSection } from "./DearshareWhoSuitableSection";
import { JoinedWriterSection } from "./JoinedWriter/JoinedWriterSection";
import { LandingIntroductionSection } from "./LandingIntro/LandingIntroductionSection";

const FunctionsSection = dynamic(
  () =>
    import("./DearshareFunctions/FunctionsSection").then(
      (mod) => mod.FunctionsSection,
    ),
  {
    ssr: false,
  },
);

const TopPostSection = dynamic(
  () =>
    import("@/features/top/components/post/TopPostSection").then(
      (mod) => mod.TopPostSection,
    ),
  {
    ssr: false,
    loading: () => <PostPlaceholder />,
  },
) as any;

const HomePageTutorial = dynamic(
  () =>
    import("@/features/section/components/home/HomePageTutorial").then(
      (mod) => mod.HomePageTutorial,
    ),
  {
    ssr: false,
  },
);

const LandingPage = () => {
  const { t } = useTranslation("landing");
  const config = getPublicConfig();
  const [canLoadComponents, setCanLoadComponents] = useState(false);

  const onEnter = () => {
    if (canLoadComponents) return;
    setCanLoadComponents(true);
  };

  return (
    <>
      <NextSeo
        description={`${config.siteName} ${t(
          "landing:landingIntroductionText",
        )}`}
        openGraph={{
          description: `${config.siteName} ${t(
            "landing:landingIntroductionText",
          )}`,
        }}
      />

      <div
        className="w-full flex flex-col items-center py-6 md:py-14 px-4 space-y-4"
        style={{
          background:
            "linear-gradient(rgba(245, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%)",
        }}
      >
        <JoinedWriterSection />
        <LandingIntroductionSection />
      </div>

      <div className="flex flex-col items-center py-6 md:py-14 px-4">
        <DearshareProSection />
      </div>

      <div className="flex flex-col py-6 md:py-14 px-4 bg-primary-100">
        <DearshareReasonSection />
      </div>

      {canLoadComponents && (
        <>
          <div className="flex flex-col py-6 md:py-14 px-4">
            <DearshareWhoSuitableSection />
          </div>

          <FunctionsSection />
          <TopPostSection
            title={
              <div className={"flex w-full justify-center"}>
                <Title>{t("topPostHeader")}</Title>
              </div>
            }
          />
        </>
      )}
      <HomePageTutorial />
      <Waypoint onEnter={onEnter} bottomOffset={-600} />
      <LandingLogin />
    </>
  );
};

export default LandingPage;
