import Job1 from "@assets/landing/whoSuitable/job1.png";
import Job2 from "@assets/landing/whoSuitable/job2.png";
import Job3 from "@assets/landing/whoSuitable/job3.png";
import Job4 from "@assets/landing/whoSuitable/job4.png";
import Job5 from "@assets/landing/whoSuitable/job5.png";
import Job6 from "@assets/landing/whoSuitable/job6.png";
import { useTranslation } from "next-i18next";
import Image, { ImageProps } from "next/image";
import React from "react";

import { Container } from "@/features/common/components/layout/Container";
import { ContentText } from "@/features/landing/components/ContentText";
import { SubTitle } from "@/features/landing/components/SubTitle";
import { Title } from "@/features/landing/components/Title";

export const DearshareWhoSuitableSection = () => {
  const { t } = useTranslation("landing");

  return (
    <Container
      maxWidth="mw1152"
      className="px-0"
      data-test-id="DearshareWhoSuitableSection-container"
    >
      <div className="flex w-full justify-center py-4">
        <Title>{t("whoSuitable.title")}</Title>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-24">
        {[Job1, Job2, Job3, Job4, Job5, Job6].map((src, idx) => (
          <Suitable
            key={idx}
            src={src}
            label={t(`whoSuitable.job${idx + 1}.label`)}
            description={t(`whoSuitable.job${idx + 1}.description`)}
          />
        ))}
      </div>
    </Container>
  );
};

const Suitable = ({
  src,
  label,
  description,
}: {
  label: string;
  description: string;
} & Pick<ImageProps, "src">) => {
  return (
    <div className="flex flex-col items-center self-center">
      <div className="max-w-[275px]">
        <Image src={src} alt={`Picture of a ${label}`} placeholder="blur" />
      </div>
      <div className="flex flex-col items-center mt-5">
        <SubTitle>{label}</SubTitle>
        <ContentText textAlignCenter={true}>{description}</ContentText>
      </div>
    </div>
  );
};
