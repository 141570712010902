import ContentImg from "@assets/landing/pros/content.png";
import FreeImg from "@assets/landing/pros/free.png";
import LimitImg from "@assets/landing/pros/limit.png";
import RespectImg from "@assets/landing/pros/respect.png";
import { useTranslation } from "next-i18next";
import React, { useMemo } from "react";

import { Container } from "@/features/common/components/layout/Container";
import { Title } from "@/features/landing/components/Title";

import { DearshareProImage } from "./DearshareProImage";

export const DearshareProSection = () => {
  const { t } = useTranslation("landing");

  const sections = useMemo(() => {
    return [
      {
        src: ContentImg,
        description: {
          title: t("landing:dearshareProValueHeader"),
          content: t("landing:dearshareProValueContent"),
        },
      },
      {
        src: LimitImg,
        description: {
          title: t("landing:dearshareProLimitHeader"),
          content: t("landing:dearshareProLimitContent"),
        },
      },
      {
        src: FreeImg,
        description: {
          title: t("landing:dearshareProFreeHeader"),
          content: t("landing:dearshareProFreeContent"),
        },
      },
      {
        src: RespectImg,
        description: {
          title: t("landing:dearshareProRespectHeader"),
          content: t("landing:dearshareProRespectContent"),
        },
      },
    ];
  }, [t]);

  return (
    <>
      <Title>{t("landing:dearshareProHeader")}</Title>

      <Container
        maxWidth="mw1152"
        className="px-0"
        data-test-id="DearshareProSection-container"
      >
        <div className="space-y-4">
          {sections.map((sect, i) => (
            <DearshareProImage
              key={i}
              {...sect}
              imagePosition={i % 2 === 0 ? "left" : "right"}
            />
          ))}
        </div>
      </Container>
    </>
  );
};
