import Chinese from "@assets/landing/reason/chinese.png";
import MonthlyPlan from "@assets/landing/reason/monthlyPlan.png";
import Privacy from "@assets/landing/reason/privacy.png";
import { useTranslation } from "next-i18next";
import React from "react";

import { Container } from "@/features/common/components/layout/Container";
import { Title } from "@/features/landing/components/Title";

import { DearshareReason } from "./DearshareReason";

export const DearshareReasonSection = () => {
  const { t } = useTranslation("landing");

  return (
    <Container
      maxWidth="mw1152"
      className="px-0 pb-4"
      data-test-id="DearshareReasonSection-container"
    >
      <div className="flex w-full justify-center py-4">
        <Title>{t("landing:reasonHeader")}</Title>
      </div>

      <div className="grid grid-cols-1 gap-7 md:grid-cols-3 md:gap-12">
        <DearshareReason
          src={Chinese}
          header={t("landing:reasonChineseHeader")}
          content={t("landing:reasonChineseContent")}
        />

        <DearshareReason
          src={Privacy}
          header={t("landing:reasonPrivacyHeader")}
          content={t("landing:reasonPrivacyContent")}
        />

        <DearshareReason
          src={MonthlyPlan}
          header={t("landing:reasonMonthlyPlanHeader")}
          content={t("landing:reasonMonthlyPlanContent")}
        />
      </div>
    </Container>
  );
};
