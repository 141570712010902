import firebase from "firebase/app";
import Link from "next/link";
import React from "react";

import { RouteName } from "@/consts";
import { AnalyticsEventEnum } from "@/consts/AnalyticsEventEnum";
import { Square } from "@/features/common/components/square/Square";
import { AvatarWithFallback } from "@/features/user/components/avatar/AvatarWithFallBack";
import { UserInformation } from "@/features/user/components/writerInformation";

type JoinedWriterProps = {
  user: UserInformation;
};

export const JoinedWriter: React.FC<JoinedWriterProps> = ({ user }) => {
  const { avatar, displayName, accountName } = user;
  const profileLink = `${RouteName.ProfileUser}${user.accountName}`;

  return (
    <Link passHref href={profileLink}>
      <div
        className="hover:opacity-80 w-full flex flex-col justify-center items-center cursor-pointer"
        data-test-id="JoinedWriter-container"
        onClick={() =>
          firebase
            .analytics()
            .logEvent(AnalyticsEventEnum.JoinedWriterClickAvatar, {
              userId: user._id,
            })
        }
      >
        <Square>
          <AvatarWithFallback
            avatarUrl={avatar?.url}
            displayName={displayName}
            accountName={accountName}
            sizeClassName="w-full h-full"
          />
        </Square>

        {/* <div className="w-full max-w-full min-w-0 mt-2 truncate text-center">
          <span className="text-xl font-medium">{displayName}</span>
        </div> */}

        <div className="w-full max-w-full min-w-0 mt-0.5 truncate text-center">
          <span className="text-md font-medium text-gray-400">
            {accountName}
          </span>
        </div>
      </div>
    </Link>
  );
};
