import clsx from "clsx";
import { FC } from "react";

export const ContentText: FC<{ textAlignCenter?: boolean }> = (props) => {
  return (
    <span
      className={clsx(
        "text-base md:text-lg",
        props.textAlignCenter && "text-center",
      )}
    >
      {props.children}
    </span>
  );
};
