import IntroductionImg from "@assets/landing/introduction/introduction.jpg";
import { useTranslation } from "next-i18next";
import Image from "next/image";

import { Container } from "@/features/common/components/layout/Container";
import { Title } from "@/features/landing/components/Title";

export const LandingIntroductionSection = () => {
  const { t } = useTranslation("landing");

  return (
    <Container
      maxWidth="mw1152"
      className="px-0"
      data-test-id="LandingIntroductionSection-container"
    >
      <div className="flex md:flex-row flex-col-reverse mb-4 space-y-4">
        <div className="md:w-5/12 flex flex-col justify-center pr-5 mt-10 md:mt-0">
          <Title className="md:pb-2 mb-4 text-4xl md:text-[2.5rem] font-semibold tracking-wider">
            {t("landing:landingIntroductionHeader")}
          </Title>

          <span className="text-base md:text-lg tracking-wider">
            {t("landing:landingIntroductionText")}
          </span>
        </div>

        <div className="md:w-7/12">
          <Image
            className="object-contain"
            alt="Dearshare Intro"
            src={IntroductionImg}
            placeholder="blur"
            priority={true}
          />
        </div>
      </div>
    </Container>
  );
};
