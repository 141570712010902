import clsx from "clsx";
import { FC } from "react";

export const SubTitle: FC<{ className?: string }> = (props) => {
  const { children, className = "text-center" } = props;
  return (
    <span className={clsx("text-20 md:text-2xl font-medium", className)}>
      {children}
    </span>
  );
};
