import Image, { ImageProps } from "next/image";
import React from "react";

import { ContentText } from "@/features/landing/components/ContentText";
import { SubTitle } from "@/features/landing/components/SubTitle";

type ReasonProps = {
  header: string;
  content: string;
} & Pick<ImageProps, "src">;

export const DearshareReason: React.FC<ReasonProps> = (props) => {
  const { src, header, content } = props;

  return (
    <div className="flex flex-col items-center">
      <div className="max-w-220 md:max-w-275">
        <Image src={src} alt={`Photo of ${header}`} placeholder="blur" />
      </div>

      <SubTitle className="mt-7 md:mt10 mb-4">{header}</SubTitle>

      <ContentText textAlignCenter={true}>{content}</ContentText>
    </div>
  );
};
