import firebase from "firebase/app";
import { useTranslation } from "next-i18next";
import Image from "next/image";

import { DialogEnum } from "@/consts";
import { AnalyticsEventEnum } from "@/consts/AnalyticsEventEnum";
import { useUIContext } from "@/features/common/hooks/UIContext/useUIContext";

export const LandingLogin = () => {
  const { openDialog } = useUIContext();

  const onClickLogin = () => {
    firebase.analytics().logEvent(AnalyticsEventEnum.LandingLogin);
    openDialog(DialogEnum.Login);
  };
  const { t } = useTranslation("landing");

  return (
    <div
      className="h-16 md:h-12 fixed flex justify-center bottom-0 left-0 right-0 bg-primary items-center z-50 space-x-4 cursor-pointer"
      onClick={onClickLogin}
      data-test-id="LandingLogin-container"
    >
      <span className="text-white text-base md:text-lg font-medium mr-2">
        {t("landing:loginLabel")}
      </span>

      <Image
        alt="right arrow"
        src="/assets/landing/rightArrow.png"
        layout="fixed"
        loading="eager"
        width={12}
        height={12}
      />
    </div>
  );
};
