import { useTranslation } from "next-i18next";
import React, { FC, useEffect, useMemo, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Square } from "@/features/common/components/square/Square";

import {
  JoinedWritersSliderProps,
  JoinedWritersSlider,
} from "./JoinedWritersSlider";

type JoinedWritersProps = JoinedWritersSliderProps;

export const JoinedWriters: FC<JoinedWritersProps> = (props) => {
  const { t } = useTranslation("landing");
  const { users } = props;

  const canMount = useMemo(() => {
    return users.length > 0;
  }, [users]);

  return (
    <div className="w-full flex flex-col md:flex-row md:items-center">
      <div className="flex w-full md:w-auto justify-center">
        <p className="text-primary font-bold text-2xl md:text-3xl whitespace-nowrap">
          {t("landing:joinedKOLHeader")}
        </p>
      </div>

      <div className="md:flex-1 mt-3 min-w-0 md:mx-5">
        <div>
          {canMount ? (
            <JoinedWritersSlider users={props.users} />
          ) : (
            <JoinedWritersPlaceholder />
          )}
        </div>
      </div>
    </div>
  );
};

export const JoinedWritersPlaceholder = () => {
  return (
    <div className="flex w-full overflow-hidden animate-pulse">
      {new Array(6).fill(0).map((_, idx) => (
        <div
          key={idx}
          className="shrink-0 w-4/12 sm:w-3/12 md:w-2/12 px-2 md:px-4 flex flex-col justify-center items-center"
          data-test-id="JoinedWriters-placeholderContainer"
        >
          <Square>
            <div className="rounded-full w-full h-full bg-gray-200" />
          </Square>
          <div className="rounded-full w-full h-4 bg-gray-200 mt-2" />

          <div className="rounded-full w-full h-3 bg-gray-200 mt-2" />
          <div className="w-full max-w-full min-w-0 justify-center mt-0.5">
            <span className="h-7 bg-gray-200 animate-pulse" />
          </div>
          <div className="h-4 bg-blue-400 rounded"> </div>
        </div>
      ))}
    </div>
  );
};
