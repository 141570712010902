import clsx from "clsx";
import { FC } from "react";

export const Title: FC<{ className?: string }> = (props) => {
  const { children, className = "pb-8 text-center" } = props;

  return (
    <span
      className={clsx(
        "text-22 md:text-32 font-medium tracking-wide",
        className,
      )}
    >
      {children}
    </span>
  );
};
