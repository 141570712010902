import { useMediaQuery, useTheme } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import firebase from "firebase/app";
import React, { FC, useMemo, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import useMeasure from "react-use/lib/useMeasure";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";

import { AnalyticsEventEnum } from "@/consts/AnalyticsEventEnum";
import { UserInformation } from "@/features/user/components/writerInformation";

import { JoinedWriter } from "./JoinedWriter";

export type JoinedWritersSliderProps = {
  users: UserInformation[];
};

export const JoinedWritersSlider: FC<JoinedWritersSliderProps> = (props) => {
  const { users } = props;
  const sliderRef = useRef<Slider>();
  const [ref, { height }] = useMeasure();
  const [sliderNextIndex, setSliderNextIndex] = useState(0);
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const sliderToShow = useMemo(() => {
    if (xsDown) return 3;
    if (smDown) return 4;
    return 6;
  }, [xsDown, smDown]);

  const beforeChange = (prev, next) => {
    setSliderNextIndex(next);
  };

  /* conditions on rendering previous and next button */
  const sliderItemsCount = users.length ?? 0;
  const showNextButton =
    users &&
    sliderItemsCount > sliderToShow &&
    sliderNextIndex + sliderToShow < sliderItemsCount;
  const showPreviousButton =
    users && sliderItemsCount > sliderToShow && sliderNextIndex !== 0;

  const sliderSettings: Settings = {
    arrows: false,
    infinite: false,
    slidesToShow: sliderToShow,
    slidesToScroll: sliderToShow,
    autoplay: false,
    centerMode: false,
    swipeToSlide: true,
    beforeChange,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: sliderToShow,
          slidesToScroll: 1,
          // variableWidth: true,
        },
      },
    ],
  };

  //Slider Button actions
  const handleNextSlider = () => {
    firebase.analytics().logEvent(AnalyticsEventEnum.JoinedWriterSliderNext);
    sliderRef.current?.slickNext();
  };

  const handlePrevSlider = () => {
    firebase
      .analytics()
      .logEvent(AnalyticsEventEnum.JoinedWriterSliderPrevious);
    sliderRef.current?.slickPrev();
  };

  return (
    <SliderWrapper>
      <div className="relative px-8">
        {showNextButton && (
          <ControlButton
            left={false}
            height={height}
            onClick={handleNextSlider}
          >
            <ChevronRightIcon />
          </ControlButton>
        )}
        {showPreviousButton && (
          <ControlButton left={true} height={height} onClick={handlePrevSlider}>
            <ChevronLeftIcon />
          </ControlButton>
        )}

        <Slider {...sliderSettings} ref={sliderRef}>
          {users.map((user) => {
            return (
              <div key={user._id} className="flex-1 px-2 md:px-4 max-w-220">
                <JoinedWriter user={user} />
              </div>
            );
          })}
        </Slider>
      </div>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  .slick-slide {
    div:focus {
      outline: none !important;
    }
  }
  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
`;
const ControlButton = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  ${(props) => (props.left ? "left:0;" : "right:0;")}
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  z-index: 10;
  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`;
