import clsx from "clsx";
import React, { FC } from "react";

type ContainerProps = {
  maxWidth?: "mw450" | "mw1152" | "sm" | "md" | "lg" | "xl";
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const classNameMap = {
  sm: "max-w-screen-sm",
  md: "max-w-screen-md",
  lg: "max-w-screen-lg",
  xl: "max-w-screen-xl",
  mw450: "max-w-450",
  mw1152: "max-w-6xl",
};

export const Container: FC<ContainerProps> = ({
  maxWidth = "lg",
  children,
  className = "px-4",
  ...props
}) => {
  return (
    <div
      className={clsx(`w-full ${classNameMap[maxWidth]} mx-auto`, className)}
      {...props}
    >
      {children}
    </div>
  );
};
